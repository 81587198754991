define("discourse/plugins/discourse-evidence/discourse/routes/evidence_uploader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Route for the path `/evidence_uploader` as defined in `../evidence_uploader-route-map.js.es6`.
   */
  var _default = _exports.default = Discourse.Route.extend({
    renderTemplate() {
      this.render('evidence_uploader');
    }
  });
});